import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import * as actions from "../actions";
import * as firebase from "firebase";
import { v4 as uuidv4 } from "uuid";

import FileUploader from "react-firebase-file-uploader";
import ListItem from "./ListItem";
import "./style.css";

class Home extends Component {
  state = {
    showForm: false,
    username: "",
    filename: "",
    avatar: "",
    isUploading: false,
    progress: 0,
    avatarURL: "",
    formValue: "example.pdf",
    dataid: "",
    selectedFile: null
  };

  handleSetFilename = event => this.setState({ filename: event.target.value });

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ avatarURL: url }));
  };

  inputNameChange = event => {
    this.setState({ formValue: event.target.value });
  };

  inputFileChange = event => {
    console.log(event.target.files[0]);
    this.setState({ selectedFile: event.target.files[0] });

    let updateFileName = event.target.files[0].name;

    //remove file extension name

    this.setState({ formValue: updateFileName });
  };

  formSubmit = event => {
    let uuid = uuidv4();
    console.log("event", event);
    const { formValue, dataid, selectedFile } = this.state;

    // const { dataid } = "df";
    const { addPDF } = this.props;

    event.preventDefault();

    //insert data into the firebase
    addPDF({ name: formValue, id: uuid, file: selectedFile });

    //reset empty value
    this.setState({ formValue: "" });
    this.setState({ dataid: "" });
    this.setState({ selectedFile: null });

    //reset form render to false
    this.setState({ showForm: false });
  };

  renderForm = () => {
    const { showForm, formValue, filename, dataid } = this.state;
    if (showForm)
      return (
        <div className="bg-yellow-300 p-8">
          <form onSubmit={this.formSubmit}>
            <div>
              <div className="flex flex-col">
                <label className="bg-red-200 text-xl">File:</label>
                <input
                  type="file"
                  name={filename}
                  accept="image/png, .pdf"
                  onChange={this.inputFileChange}
                  // id=""
                />
              </div>
              <div className="flex flex-col">
                <label className="bg-red-200 text-xl">File name</label>
                <input
                  value={formValue}
                  onChange={this.inputNameChange}
                  id=""
                  type="text"
                />
              </div>
            </div>
            <div className="flex justify-end">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Submit
              </button>
            </div>
            {/* <label>Avatar:</label>
            {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
            {this.state.avatarURL} */}
            {/* {this.state.avatarURL && <img src={this.state.avatarURL} />} */}
            {/* <FileUploader
            accept="image/*"
            name="avatar"
            randomizeFilename
            storageRef={firebase.storage().ref("images")}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess}
            onProgress={this.handleProgress}
          /> */}
          </form>
        </div>
      );
  };

  renderPDFList() {
    const { data } = this.props;
    console.log("data", data);

    const pdflist = _.map(data, (value, index) => {
      return <ListItem key={index} pdfId={index} pdf={value} />;
    });

    if (!_.isEmpty(pdflist)) {
      console.log("pdf list", pdflist);
      return (
        <table className="table-fixed">
          <thead>
            <tr className="">
              <th className="border w-1/4 px-4 py-2">ID</th>
              <th className="border w-1/4 px-4 py-2">File Name</th>
              <th className="border w-1/4 px-4 py-2">URL</th>
              <th className="border w-1/4 px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>{pdflist}</tbody>
        </table>
      );
    } else {
      return (
        <div className="flex justify-center bg-red-200 p-8">
          <p className="text-2xl">No PDF files is in firebase Store!!</p>
        </div>
      );
    }
  }

  componentWillMount() {
    this.props.fetchPDFList();
    console.log(this.props.fetchPDFList());
  }

  render() {
    const { showForm } = this.state;
    return (
      <div className="to-do-list-container">
        <div className="row">
          {this.renderForm()}
          {this.renderPDFList()}
          <div className="fixed-action-btn">
            <button
              onClick={() => this.setState({ showForm: !showForm })}
              className="btn-floating btn-large black darken-4"
            >
              {showForm ? (
                <i className="large material-icons">-</i>
              ) : (
                <i className="large material-icons">+</i>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    data
  };
};

export default connect(mapStateToProps, actions)(Home);
