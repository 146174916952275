import React, { Component } from "react";
// import List from "./components/List";
import Home from "./components/Home";

class App extends Component {
  render() {
    return (
      <div className="container">
        <Home />
      </div>
    );
  }
}
export default App;
