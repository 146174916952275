import React, { Component } from "react";
import { connect } from "react-redux";
// import { completeToDo } from "../actions";
import { deletePDF } from "../actions";

class ListItem extends Component {
  //   completeClick = completeTodoId => {
  //     const { completeToDo } = this.props;
  //     completeToDo(completeTodoId);
  //   };

  deleteClick = deletePDFId => {
    const { deletePDF } = this.props;
    console.log(deletePDF);
    deletePDF(deletePDFId);
  };

  render() {
    console.log("render this.props", this.props);
    const { pdfId, pdf } = this.props;
    console.log("pdfId, pdf", pdfId, pdf);

    return (
      <tr key="" className="bg-gray-300">
        <td className="text-base w-1/4">
          {pdf.id == null ? "no data" : pdf.id}
        </td>
        <td className="text-base w-1/4">
          {pdf.name == null ? "no data" : pdf.name}
        </td>
        <td className="text-base w-1/4">
          {pdf.url == null ? "no data" : pdf.url}
        </td>
        <td className="w-1/4">
          <button
            onClick={() => this.deleteClick(pdfId)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Delete
          </button>
        </td>
      </tr>
    );
  }
}

export default connect(null, { deletePDF })(ListItem);
